// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-d-face-cosmetic-consultation-js": () => import("./../../../src/pages/3d-face-cosmetic-consultation.js" /* webpackChunkName: "component---src-pages-3-d-face-cosmetic-consultation-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-beautician-test-js": () => import("./../../../src/pages/beautician-test.js" /* webpackChunkName: "component---src-pages-beautician-test-js" */),
  "component---src-pages-complaint-process-js": () => import("./../../../src/pages/complaint-process.js" /* webpackChunkName: "component---src-pages-complaint-process-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-test-js": () => import("./../../../src/pages/covid-test.js" /* webpackChunkName: "component---src-pages-covid-test-js" */),
  "component---src-pages-deep-plane-facelift-js": () => import("./../../../src/pages/deep-plane-facelift.js" /* webpackChunkName: "component---src-pages-deep-plane-facelift-js" */),
  "component---src-pages-deep-plane-test-js": () => import("./../../../src/pages/deep-plane-test.js" /* webpackChunkName: "component---src-pages-deep-plane-test-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-fillers-and-btx-js": () => import("./../../../src/pages/fillers-and-btx.js" /* webpackChunkName: "component---src-pages-fillers-and-btx-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-male-facelift-js": () => import("./../../../src/pages/male-facelift.js" /* webpackChunkName: "component---src-pages-male-facelift-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-rhinoplasty-js": () => import("./../../../src/pages/rhinoplasty.js" /* webpackChunkName: "component---src-pages-rhinoplasty-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */)
}

